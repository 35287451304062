////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgb(251, 251, 251);
}
.bg-secondaryColor {
  background-color: rgb(251, 251, 251);
}
.bg-thirdColor {
  background-color: rgb(251, 251, 251);
}
.bg-fourthColor {
  background-color: rgb(249, 156, 1);
}
.bg-fifthColor {
  background-color: rgb(251, 251, 251);
}
.bg-sixthColor {
  background-color: rgb(249, 156, 1);
}
.bg-seventhColor {
  background-color: rgb(251, 251, 251);
}
